.form-container {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
  
  .form-header {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .form {
    margin: 2rem auto;
    border: 1px solid #ccc;
    padding: 2rem;
    max-width: 400px;
  }
  
  .form-field {
    margin: 1rem 0;
    padding: 1rem 0;
  }
  
  .error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }

  .input-error {
    font-size: 0.8rem;
    margin-top: -15px;
    margin-bottom: 1rem;
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .form-style {
    width: 50%;
    padding: 0px 50px;
  }

  .form-input {
    margin: 20px 0px;
  }

.form-btn {
  width: 100%;
  margin: 20px 0px;
}

.label-form {
  margin-top: 25px !important;
  color: #007bff !important;
}

.title-form {
  color: #007bff !important;
  text-align: center ;
}

@media(max-width: 1100px) {
  .form-style {
    width: 80%;
    padding: 0px 50px;
  }
}

@media(max-width: 480px) {
  .form-style {
    width: 100%;
    padding: 0px 25px;
  }
}
  