.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #00125A;
}
.home-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo {
  width: 64px;
}
.home-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-centered {
  width: 100%;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-left {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.home-logo1 {
  width: 105px;
  margin-left: var(--dl-space-space-halfunit);
}
.home-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.home-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-sign-in {
  margin-right: 20px;
}
.home-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started:hover {
  background-color: #FF4D00;
}
.home-text06 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.home-header {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.home-text08 {
  color: #EEE9FE;
  font-size: 20px;
  max-width: 600px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-get-started1 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: 40px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started1:hover {
  color: black;
  background-color: #ffffff;
}
.home-text09 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: #FF4D00;
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  background-color: #FF4D00;
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: #FEBB61;
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  background-color: #FEBB61;
}

.home-animation-container {
  width: 50vw;
  background-color: white;
  padding: 40px 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image {
  width: 500px;
  user-select: none;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text10 {
  font-size: 56px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 72px;
}
.home-text14 {
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon2 {
  width: 32px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text17 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text18 {
  line-height: 24px;
}
.home-publish {
  height: initial;
}
.home-icon3 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text19 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text20 {
  line-height: 24px;
}
.home-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon4 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text21 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text22 {
  line-height: 24px;
}
.home-icon5 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text23 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text24 {
  line-height: 24px;
}
.home-quote-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 160px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: 160px;
  justify-content: center;
  background-color: #000000;
}
.home-quote {
  width: 100%;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
}
.home-message {
  color: rgb(255, 255, 255);
  font-size: 40px;
  max-width: 800px;
  line-height: 52px;
}
.home-author {
  width: 800px;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}
.home-quote1 {
  color: rgb(151, 126, 249);
  width: 100%;
  max-width: 800px;
  margin-left: var(--dl-space-space-unit);
}
.home-text25 {
  font-style: normal;
  font-weight: 600;
}
.home-statistics {
  width: 100%;
  height: 264px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #5228F5;
}
.home-container3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container4 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text28 {
  text-align: left;
}
.home-text29 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started2 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text30 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.home-banner-advanced-analytics {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container1 {
  flex: 1;
  display: flex;
}
.home-cards-image1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right1 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text32 {
  text-align: left;
}
.home-category {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text33 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text34 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text35 {
  color: #5228F5;
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
}
.home-text35:hover {
  color: #000000;
}
.home-category1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text36 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text37 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text38 {
  color: #5228F5;
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
}
.home-text38:hover {
  color: #000000;
}
.home-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text39 {
  color: rgb(82, 40, 245);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text40 {
  text-align: left;
}
.home-text41 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started3 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text42 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-integrations {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sevenunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text45 {
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-pills-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pills {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.home-container6 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.home-container7 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: center;
}
.no-contest-found {
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #8ED1E9;
}
.home-centered-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-heading1 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}
.home-text46 {
  color: #00125A;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text47 {
  color: var(--dl-color-gray-white);
}
.home-category2 {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #5228F5;
}
.home-headng {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text48 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text49 {
  color: #EEE9FE;
  line-height: 24px;
  margin-bottom: 40px;
}
.home-get-started4 {
  margin-bottom: 0px;
}
.home-get-started4:hover {
  color: black;
  background-color: #ffffff;
}
.home-text50 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-container8 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.home-pasted-image {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category3 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-headng1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text51 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text52 {
  color: #1E1E1E;
  line-height: 24px;
}
.home-pasted-image1 {
  width: 100%;
}
.home-category4 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text53 {
  color: #ffffff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text54 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.home-pasted-image2 {
  width: 100%;
  object-fit: contain;
}
.contest-form-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media(max-width: 990px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-logo1 {
    width: 64px;
  }
  .home-links1 {
    display: none;
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started {
    display: none;
  }
  .home-mobile-menu-button {
    display: flex;
  }
  .home-header {
    width: 100%;
    max-width: 1200px;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 350px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text10 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text14 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text18 {
    line-height: 21px;
  }
  .home-text20 {
    line-height: 21px;
  }
  .home-text22 {
    line-height: 21px;
  }
  .home-text23 {
    font-size: 24px;
  }
  .home-text24 {
    line-height: 21px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-sevenunits);
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-message {
    width: 100%;
    max-width: 800px;
  }
  .home-author {
    width: 100%;
    max-width: 800px;
  }
  .home-statistics {
    height: 800px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container3 {
    height: 100%;
    flex-direction: column;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left1 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-cards-image {
    width: 360px;
  }
  .home-banner-advanced-analytics {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container7 {
    margin-left: 0px;
  }
  .home-category2 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-container8 {
    width: 100%;
  }
  .home-pasted-image {
    height: auto;
  }
  .home-headng1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-headng2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 760px) {
  .home-animation-container {
    width: 50vw;
  }
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: #00125A;
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-left {
    margin-left: var(--dl-space-space-unit);
  }
  .home-right {
    margin-right: var(--dl-space-space-unit);
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started {
    display: none;
  }
  .home-header {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text07 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text08 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .home-get-started1 {
    background-color: white;
    color: #00125A;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-dashboard-preview {
    top: 520px;
    width: 55vw;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 400px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text10 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text17 {
    font-size: 24px;
  }
  .home-text18 {
    font-size: 14px;
  }
  .home-publish {
    margin-left: 0px;
  }
  .home-text19 {
    font-size: 24px;
  }
  .home-text20 {
    font-size: 14px;
  }
  .home-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze {
    margin-right: 0px;
  }
  .home-text21 {
    font-size: 24px;
  }
  .home-text22 {
    font-size: 14px;
  }
  .home-text24 {
    font-size: 14px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-message {
    font-size: 24px;
    line-height: 31px;
  }
  .home-author {
    width: 100%;
  }
  .home-avatar {
    width: 40px;
    height: 40px;
  }
  .home-quote1 {
    font-size: 14px;
    max-width: 800px;
  }
  .home-statistics {
    height: 100%;
  }
  .home-container3 {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container4 {
    flex-direction: column;
  }
  .home-left1 {
    margin-right: 0px;
  }
  .home-text28 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text29 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text30 {
    font-size: 16px;
  }
  .home-image-container {
    justify-content: center;
  }
  .home-cards-image {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-banner-advanced-analytics {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container {
    flex-direction: column-reverse;
  }
  .home-right1 {
    margin-left: 0px;
  }
  .home-text32 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text33 {
    font-size: 20px;
  }
  .home-text34 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text36 {
    font-size: 20px;
  }
  .home-text37 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container5 {
    flex-direction: column;
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-integrations {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text45 {
    line-height: 24px;
  }
  .home-pills {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text47 {
    margin-bottom: 0px;
  }
  .home-headng {
    padding-bottom: 0px;
  }
  .home-get-started4 {
    display: none;
  }
  .home-container8 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
}
@media(max-width: 480px) {
  .form-style {
    width: 100%;
  }
  .home-dashboard-preview {
    width: 80vw;
  }
  .home-animation-container {
    width: 70vw;
  }
  .home-hero {
    padding-bottom: 160px;
  }
  .home-logo {
    margin-left: 0px;
  }
  .home-icon {
    align-items: center;
    justify-content: center;
  }
  .home-text08 {
    color: #EEE9FE;
  }
  .home-get-started1 {
    width: 100%;
  }
  .home-dashboard-preview {
    top: 500px;
  }
  .home-features {
    padding-top: 400px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze {
    margin-bottom: 0px;
  }
  .home-statistics {
    height: 500px;
  }
  .home-text29 {
    line-height: 24px;
  }
  .home-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-category2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }

}
